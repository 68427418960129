import React, { useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { classNames, TranslatedString } from "@ct-react/core";
import { SkeletonableProps } from "../../../tools/components";
import { LangWarning } from "../../common/info-box";
import "./costs.scss";

interface CostsData {
  cleaning?: TranslatedString,
  bed?: TranslatedString,
  kitchen?: TranslatedString,
  bath?: TranslatedString,
  parking?: TranslatedString,
  pet?: TranslatedString,
  benefits?: TranslatedString,
  tax?: TranslatedString,
  deposit?: TranslatedString,
  charges?: TranslatedString,
  cancellation?: TranslatedString,
}

const transDefs = defineMessages({
  title: { id: "booking-charges-title", defaultMessage: "Frais et prestations de ce logement" },
  translation: { id: "booking-mixed-costs-lang", defaultMessage: "Certaines informations ne sont pas disponibles dans votre langue." },
  additional: { id: "booking-costs-benefits", defaultMessage: "Prestations complémentaires" },
  noInfo: { id: "booking-no-costs-info", defaultMessage: "Il n'y a aucune information pour ce logement." },
  cleaning: { id: "booking-costs-cleaning", defaultMessage: "Nettoyage" },
  bed: { id: "booking-costs-bed", defaultMessage: "Draps de lit" },
  kitchen: { id: "booking-costs-kitchen", defaultMessage: "Linges de cuisine" },
  bath: { id: "booking-costs-bath", defaultMessage: "Linges de bain" },
  parking: { id: "booking-costs-parking", defaultMessage: "Parking" },
  pet: { id: "booking-costs-pet", defaultMessage: "Animaux domestiques" },
  deposit: { id: "booking-costs-deposit", defaultMessage: "Caution" },
  tax: {  id: "booking-costs-tax", defaultMessage: "Taxes de séjour" },
  cancellation: { id: "booking-costs-cancellation", defaultMessage: "Assurance annulation" },
  charges: { id: "booking-costs-charges", defaultMessage: "Frais d'administration" }
});

const DisplayCosts = (content: CostsData) => {

  const intl = useIntl();

  return (
    <>
      {!!content.cleaning &&
        <div className="a-cost">
          <span>{intl.formatMessage(transDefs.cleaning)}</span>
          <span>{content.cleaning.value}</span>
        </div>
      }
      {!!content.bed &&
        <div className="a-cost">
          <span>{intl.formatMessage(transDefs.bed)}</span>
          <span>{content.bed.value}</span>
        </div>
      }
      {!!content.kitchen &&
        <div className="a-cost">
          <span>{intl.formatMessage(transDefs.kitchen)}</span>
          <span>{content.kitchen.value}</span>
        </div>
      }
      {!!content.bath &&
        <div className="a-cost">
          <span>{intl.formatMessage(transDefs.bath)}</span>
          <span>{content.bath.value}</span>
        </div>
      }
      {!!content.parking &&
        <div className="a-cost">
          <span>{intl.formatMessage(transDefs.parking)}</span>
          <span>{content.parking.value}</span>
        </div>
      }
      {!!content.pet &&
        <div className="a-cost">
          <span>{intl.formatMessage(transDefs.pet)}</span>
          <span>{content.pet.value}</span>
        </div>
      }
      {!!content.deposit &&
        <div className="a-cost">
          <span>{intl.formatMessage(transDefs.deposit)}</span>
          <span>{content.deposit.value}</span>
        </div>
      }
      {!!content.tax &&
        <div className="a-cost">
          <span>{intl.formatMessage(transDefs.tax)}</span>
          <span>{content.tax.value}</span>
        </div>
      }
      {!!content.cancellation &&
        <div className="a-cost">
          <span>{intl.formatMessage(transDefs.cancellation)}</span>
          <span>{content.cancellation.value}</span>
        </div>
      }
      {!!content.charges &&
        <div className="a-cost">
          <span>{intl.formatMessage(transDefs.charges)}</span>
          <span>{content.charges.value}</span>
        </div>
      }
    </>);
}

type CostsProps = SkeletonableProps<CostsData>;

const Costs = (
  {
    loading,
    className,
    data
  }: CostsProps) => {

  const intl = useIntl();

  const unMatchedLang = useMemo(() =>
    Object.values(data || {}).filter(c => !!c && typeof c === "object").some(c => !c.matchLang),[ data ]);

  return (
    <div className={classNames("rla-costs", className)}>
      <h2>
        {!loading
          ? intl.formatMessage(transDefs.title)
          : <Skeleton inline={true} width="50%"/>
        }
      </h2>
      {!loading
        ? <>
          {unMatchedLang && <LangWarning msg={intl.formatMessage(transDefs.translation)} />}
          <div className="costs-list">
            <DisplayCosts {...data!} />
          </div>
          {!!data?.benefits &&
            <div className="costs-additional">
              <span>{intl.formatMessage(transDefs.additional)}</span>
              <div className="format-ih" dangerouslySetInnerHTML={{ __html: data.benefits.value }} />
            </div>
          }
          {Object.values(data!).every(c => !c) &&
            <LangWarning msg={intl.formatMessage(transDefs.noInfo)} />
          }
        </>
        : <Skeleton containerClassName="costs-list" className="a-cost" count={10} inline={true}/>
      }
    </div>);

}

export default Costs;
