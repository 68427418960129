import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { IconFile, IconFileTypeDoc, IconFileTypePdf, IconFileTypeTxt, IconFileTypeZip } from "@tabler/icons-react";
import { classNames, TranslatedString } from "@ct-react/core";
import { SkeletonableProps } from "../../../tools/components";
import "./documents.scss";

type DocumentProps = {
  url: string;
  mimeType?: string;
  title: TranslatedString;
  alternativeText?: TranslatedString;
}

const Documents = (
  {
    loading,
    className,
    data
  }: SkeletonableProps<DocumentProps[]>) => {

  const intl = useIntl();
  const wrapperClasses = classNames("rla-documents", className);

  const icon = useCallback((type?: string) => {
    switch (type) {
      case "application/pdf":
        return <IconFileTypePdf />;
      case "application/vnd.rar":
      case "application/zip":
      case "application/x-zip-compressed":
      case "application/gzip":
      case "application/x-gzip":
        return <IconFileTypeZip />
      case "application/msword":
      case "application/vnd.ms-word.document.macroenabled.12":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      case "application/vnd.oasis.opendocument.presentation":
        return <IconFileTypeDoc />
      case "application/rtf":
      case "text/plain":
        return <IconFileTypeTxt />
      default:
        return <IconFile />;
    }
  }, []);

  if (loading)
    return (
      <div className={wrapperClasses}>
        <h2><Skeleton inline={true} width="35%" /></h2>
        <Skeleton containerClassName="doc-list skeleton" className="a-doc" count={2} width="50%" inline={true} />
      </div>);

  if (!data || data.length === 0)
    return null;

  return (
    <div className={wrapperClasses}>
      <h2>
        {intl.formatMessage({
          id: "booking-documents-title",
          defaultMessage: "{count, plural, =1 {Document} other {Documents}}"
        }, { count: data.length })}
      </h2>
      <div className="doc-list">
        {data.map((doc, i) => (
          <Link key={i} to={doc.url} target="_blank" className="a-doc">
            <div className="doc-icon">{icon(doc.mimeType)}</div>
            <div className="doc-detail">
              {doc.title.value}
              {!!doc.alternativeText && <span>{doc.alternativeText.value}</span>}
            </div>
          </Link>)
        )}
      </div>
    </div>);

}

export default Documents;
